<template>
    <div class="table">
        <div :class="['topbar', { expanded: isExpanded }]">
            <div class="sales-information"></div>
            <div class="content">
                <div class="navBar">
                    <div class="nav-elements">
                        <button @click="printTimeframe('Tarde')" class="printer"></button>
                        <div class="timeframe">
                            <span class="title">Tarde</span>
                            <span class="description">Entre 22-23h</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-sections hide-scrollbar" ref="scrollContainer4">
            <div class="product-content">
                <div class="products">
                    <div class="item" v-for="product in filteredProducts" :key="product.id">
                        <div class="icon" :style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                        <div class="content">
                            <div class="name">
                                {{ product.name }}
                            </div>
                            <div class="container">{{ product.packages[product.active_package] ? getContainerName(product.packages[product.active_package].name) : '' }}</div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="forecast">
                        <div class="item" v-for="product in filteredProducts" :key="product.id" ref="scrollContainer">
                            <span style="cursor: pointer" @click="printProduct(product.id, 'total')" class="value">{{ getTotal(product.id).total }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'defrostView',
    data() {
        return {
            isExpanded: false
        }
    },
    props: {
        products: {
            type: Array
        },
        date: { type: String }
    },
    computed: {
        forecast() {
            return this.$store.getters['production/getForecast']
        },
        filteredProducts() {
            // filter only products this.products.filter((product) that are type 2 or 4
            return this.products.filter((product) => [2, 4].includes(product.type))
        }
    },
    methods: {
        getTotal(productId) {
            var product = this.filteredProducts.find((item) => item.id === productId)
            var units = parseFloat(product.packages[product.active_package].units)
            var factor = 1
            if (this.forecast && this.forecast.products && typeof this.forecast.products.defrost[productId] !== 'undefined') {
                return {
                    total: Math.ceil(this.forecast.products.defrost[productId] / units / factor) * factor
                }
            }

            return {
                total: '-'
            }
        },
        toggleSalesSection() {
            this.isExpanded = !this.isExpanded

            var self = this
            this.$nextTick(() => {
                if (self.$refs.scrollContainer4) {
                    self.$refs.scrollContainer4.scrollTo({ top: 0, behavior: 'smooth' })
                }
            })
        },
        refresh() {
            this.isExpanded = false
            this.loadForecast()
        },
        loadForecast() {
            let self = this
            this.$overlay.loading()
            this.$store.dispatch('production/loadForecast', this.date).then(() => {
                self.$overlay.hide()
            })
        },

        printTimeframe(timeframe) {
            var self = this
            let references = []
            this.filteredProducts.forEach((product) => {
                let value = self.getTotal(product.id).total
                value = value == '-' || value == '' ? 0 : value
                if (value > 0) {
                    var product_labels = self.calculateProductLabels(product.id)
                    references.push(...product_labels)
                }
            })

            references = references.filter((item) => item.quantity > 0)

            this.$popup.confirm({
                message: 'Vas a imprimir las etiquetas de <b>' + timeframe + '</b> (podrás modificar la cantidad de cada producto) ¿Deseas imprimir las etiquetas?',
                textSave: 'Imprimir',
                textCancel: 'Cancelar',
                callSave: function () {
                    self.$store.dispatch('label/prepareTemporalCollection', references).then((response) => {
                        log(response)
                        self.$router.push({ name: 'Label', params: { print: true, type: 'collection', id: response.id } })
                    })
                }
            })
        },

        calculateProductLabels(id) {
            var product = this.filteredProducts.find((item) => item.id === id)
            if (product.length > 0) {
                product = product[0]
            }

            var units = product.packages[product.active_package].units
            var suffix = product.packages[product.active_package].name.includes('gr)') ? 'gr' : 'ud'
            var max_units = 1
            var calc = units
            var format = suffix
            var transform = false
            if (product.packages[product.active_package].label) {
                calc = product.packages[product.active_package].label.calc
                format = product.packages[product.active_package].label.units
                max_units = parseInt(product.packages[product.active_package].name.match(/\((\d+)/)[1]) || 1
                transform = true
            }
            const factor = 0.1

            let references = []

            // if timeframe is total then we need the sum of morning and afternoon
            var value = this.forecast.products.defrost[id]

            if (value > 0) {
                references.push({
                    id: product.id,
                    state_id: product.label_states.defrost,
                    quantity: Math.floor(value / max_units / calc),
                    package: transform ? max_units + format : units + format
                })
                var remaining = value % units

                function roundToNearest(value, x) {
                    let step = x / 10
                    let values = Array.from({ length: 10 }, (_, i) => step * (i + 1))
                    return values.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev))
                }

                remaining = roundToNearest(remaining, units)

                if (remaining > 0) {
                    references.push({
                        id: product.id,
                        state_id: product.label_states.defrost,
                        quantity: 1,
                        package: transform ? (Math.round((remaining * max_units) / units) == 0 ? 1 : Math.round((remaining * max_units) / units)) + format : Math.round(remaining) + format
                    })
                }
            }

            // group references by package summing quantity
            const groupedReferences = references.reduce((acc, curr) => {
                const key = curr.package
                if (!acc[key]) {
                    acc[key] = { ...curr, quantity: 0 }
                }
                acc[key].quantity += curr.quantity
                return acc
            }, {})

            // remove references with quantity 0 to groupedReferences
            Object.keys(groupedReferences).forEach((key) => {
                if (groupedReferences[key].quantity === 0) {
                    delete groupedReferences[key]
                }
            })

            return Object.values(groupedReferences)
        },

        printProduct(id, timeframe) {
            var self = this
            var value = this.getTotal(id)[timeframe]
            var product = this.filteredProducts.find((item) => item.id === id)

            var references = this.calculateProductLabels(id)
            var total_labels = references.reduce((acc, curr) => acc + curr.quantity, 0)

            if (total_labels > 0) {
                this.$popup.confirm({
                    message: 'Vas a imprimir ' + total_labels + ' etiquetas de <b>' + product.name + '</b> (podrás modificar la cantidad) ¿Deseas imprimir las etiquetas?',
                    textSave: 'Imprimir',
                    textCancel: 'Cancelar',
                    callSave: function () {
                        self.$store.dispatch('label/prepareTemporalCollection', references).then((response) => {
                            log(response)
                            self.$router.push({ name: 'Label', params: { print: true, type: 'collection', id: response.id } })
                        })
                    }
                })
            }
        },
        getContainerName(name) {
            return name.split('(')[0].trim()
        }
    }
}
</script>
<style lang="scss" scoped>
.table {
    display: flex;
    flex-direction: column;
    height: 100%;

    .positive {
        font-family: $text-bold !important;
        color: $color-success-500;
    }
    .negative {
        font-family: $text-bold !important;
        color: $color-error-500;
    }

    .topbar {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        overflow: hidden;

        .content {
            display: flex;
            width: 60%;
            justify-content: flex-end;
        }

        button {
            height: 50px;
            width: 50px;
            font-size: 30px;
            background-color: #fff;
        }
        .sales-information {
            width: 80%;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            flex-shrink: 0;
            height: 50px;
            gap: 8px;

            .nav {
                border-radius: 4px;
                display: flex;
                align-self: flex-start;
                width: 50px;
                height: 50px;
                align-items: center;
                &.sales-button {
                    @include background($image: img('chart_inactive.svg'), $size: 20px, $color: #fff);
                }
                &.active {
                    &.sales-button {
                        @include background($image: img('chart_active.svg'), $size: 20px, $color: $main);
                    }
                }
                &.refresh {
                    @include background($image: img('reload.svg'), $size: 20px, $color: #fff);
                }
            }
        }
        .navBar {
            width: calc(100% - 0px);
            display: grid;
            justify-items: start;
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;

            .nav-elements {
                //width: 250px;
                @include display-flex();
                @include align-items();
                gap: 8px;

                .printer {
                    border-radius: 4px;
                    display: flex;
                    align-self: flex-start;
                    width: 50px;
                    height: 50px;
                    align-items: center;
                    @include background($image: img('printer_b3b3b3.svg'), $size: 20px, $color: #fff);
                }
                .timeframe {
                    @include display-flex();
                    flex-direction: column;

                    .title {
                        text-transform: uppercase;
                        color: $color-black;
                        @include font-size(m);
                        font-family: $text-bold;
                    }

                    .description {
                        color: $color-neutral-600;
                        @include font-size(xs);
                        font-family: $text;
                        line-height: 14px;
                    }
                }
            }

            .nav {
                border-radius: 4px;
                width: 50px;
                height: 50px;

                &.left {
                    @include background($image: img('left_dark.svg'), $size: 20px, $color: #fff);
                }
                &.right {
                    @include background($image: img('right_dark.svg'), $size: 20px, $color: #fff);
                }
            }
            .hours {
                width: calc(100% - 100px);
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                overflow-x: hidden;
                scroll-behavior: smooth;
                gap: 25px;
                padding: 0 0px;

                .scroll-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 80px;
                    height: 100%;
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                    margin-left: 5px;

                    p {
                        font-family: $text;
                        font-size: 20px;
                        color: $color-neutral-600;
                    }
                }
                & .selected {
                    border: 2px solid $color-warning-500;
                    p {
                        font-family: $text-bold;
                    }
                }
            }
        }
        .summary-title {
            text-align: center;
            width: 125px;
            flex-shrink: 0;

            span {
                word-wrap: break-word;
                @include font-size(m);
                color: $color-neutral-600;
                font-family: $text-bold;
                text-transform: uppercase;
            }
        }
    }
    .content-sections {
        height: 90%;
        overflow: auto;
        .sales-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            .sales-text {
                width: 40%;
                padding-left: 40px;
                span {
                    display: block;
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
            .sales-content {
                width: 60%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                box-sizing: border-box;
                overflow: hidden;
                .sales-forecast {
                    width: calc(100% - 100px);
                    display: grid;
                    flex-direction: column;
                    grid-template-rows: repeat(4, 1fr);
                    align-items: center;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    scroll-behavior: smooth;

                    .row {
                        display: grid;
                        flex-direction: row;
                        gap: 25px;
                        width: 100%;
                        grid-template-columns: repeat(1, 1fr);
                        text-align: center;

                        span {
                            text-align: center;
                        }

                        .scroll-item {
                            flex: 0 0 80px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100px;
                            max-width: 100px;
                            min-width: 100px;
                            margin-left: 5px;

                            span {
                                text-align: center;
                                width: 100px;
                                font-family: $text;

                                &.positive {
                                    font-family: $text-bold;
                                    color: $color-success-500;
                                }
                                &.negative {
                                    font-family: $text-bold;
                                    color: $color-error-500;
                                }
                            }
                        }
                    }
                }
            }
            .sales-summary {
                width: 125px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                span {
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
        }
        .product-content {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 16px;

            .products {
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .item {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.6);
                    }
                    &.frost {
                        &:hover {
                            background-color: $main-t90;
                        }
                    }

                    .icon {
                        width: 35px;
                        height: 35px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        display: inline-block;
                    }
                    .content {
                        flex-direction: column;
                        width: 100%;
                        padding-left: 8px;
                        .name {
                            display: flex;
                            align-items: center;
                            font-family: $text-bold;
                            text-transform: uppercase;
                            @include font-size(sm);
                        }
                        .icon {
                            /* background-image: img('/img/defrost.svg'); */
                            height: 25px;
                            width: 25px;
                        }
                        .container {
                            display: block;
                            font-family: $text;
                            color: $color-neutral-600;
                            display: block;
                        }
                    }
                }
            }

            .content {
                display: flex;
                width: 20%;
                justify-content: flex-end;

                .forecast {
                    width: calc(100% - 0px);
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: stretch;
                    flex-direction: column;
                    gap: 10px;

                    .item {
                        height: 50px;
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        flex-direction: row;
                        align-items: center;
                        overflow-x: hidden;
                        scroll-behavior: smooth;
                        padding: 0;
                        gap: 25px;
                        width: 100%;

                        .value {
                            font-family: $text;
                            @include font-size(m);
                            display: block;
                            height: 45px;
                            background: #fff;
                            border: 1px solid #ddd;
                            border-radius: 3px;
                            @include display-flex();
                            @include align-items();
                            @include justify-content();
                            position: relative;
                            //width: 250px;

                            .increase {
                                display: block;
                                position: absolute;
                                bottom: 0px;
                                @include font-size(xs);
                                display: flex;
                                align-items: center;

                                &:before {
                                    display: inline-block;
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-radius: 3px;
                                    margin-right: 2px;
                                }

                                &.positive {
                                    &:before {
                                        border-bottom: 6px solid green;
                                    }
                                }
                                &.negative {
                                    &:before {
                                        border-top: 6px solid #f00;
                                    }
                                }
                            }

                            &.current {
                                background-color: $color-warning-500;
                                border-color: $color-warning-500;
                                font-family: $text-bold;
                                color: #fff;

                                .value {
                                    color: #fff;
                                }
                                .increase {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            .summary {
                width: 125px;
                text-align: center;
                .item {
                    width: 100%;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .value {
                        font-family: $text-bold;
                        @include font-size(l);
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }
        }
    }
}

//RBI DEVICE
@media (max-width: 1285px) {
    .table {
        .topbar {
            .navBar {
                .nav-elements {
                    .timeframe {
                        .title {
                            @include font-size(sm);
                        }
                        .description {
                            @include font-size(xs);
                        }
                    }
                    .printer {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}
</style>
