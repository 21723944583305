<template>
    <div class="table">
        <div :class="['topbar', { expanded: isExpanded }]">
            <div class="sales-information">
                <button class="nav sales-button" :class="{ active: isExpanded }" @click="toggleSalesSection"></button>
                <button class="nav mail" @click="sendReportMail"></button>
                <!-- <button class="nav refresh" @click="refresh"></button> -->
                <div class="switch-container">
                    <label class="switch">
                        <input type="checkbox" v-model="showBaths" />
                        <span class="slider"></span>
                    </label>
                    <span class="switch-label">{{ showBaths ? 'Baños' : 'Producción' }}</span>
                </div>
            </div>
            <div class="content">
                <div class="navBar">
                    <div class="header-group">
                        <div class="header">RECOMENDACIÓN</div>
                        <div class="columns-group">
                            <div class="nav-elements">
                                <button class="printer"></button>
                                <div
                                    class="timeframe"
                                    :class="{
                                        current: currentTimeframe == 'morning'
                                    }"
                                    style="cursor: pointer"
                                    @click="printTimeFrame('morning')"
                                >
                                    <span class="title">Mañana</span>
                                    <span class="description">Aper. - 16.00h</span>
                                </div>
                            </div>
                            <div class="nav-elements">
                                <button class="printer"></button>
                                <div
                                    class="timeframe"
                                    :class="{
                                        current: currentTimeframe == 'afternoon'
                                    }"
                                    style="cursor: pointer"
                                    @click="printTimeFrame('afternoon')"
                                >
                                    <span class="title">Tarde</span>
                                    <span class="description">16.00h - cierre</span>
                                </div>
                            </div>
                            <div class="nav-elements" style="cursor: pointer" @click="printTimeFrame('total')">
                                <button class="printer"></button>
                                <div class="timeframe">
                                    <span class="title">Total</span>
                                    <span class="description">Todo el día</span>
                                </div>
                            </div>
                            <div class="nav-elements" style="cursor: pointer" @click="printTimeFrame('recalculated')">
                                <button class="printer"></button>
                                <div class="timeframe">
                                    <span class="title">Recalculado</span>
                                    <span class="description">Total ajustado</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-group">
                        <div class="header">IMPRESIÓN</div>
                        <div class="nav-elements">
                            <button class="printer"></button>
                            <div class="timeframe">
                                <span class="title">Total</span>
                                <span class="description">Etiquetas impresas</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-sections hide-scrollbar" ref="scrollContainer4">
            <div v-if="isExpanded" class="sales-section">
                <div class="sales-text">
                    <span> {{ $t('production.sales.projected') }}</span>
                    <span> {{ $t('production.sales.real') }}</span>
                    <span> {{ $t('production.sales.difference') }}</span>
                    <span> {{ $t('production.sales.percentage_difference') }}</span>
                </div>
                <div class="sales-content">
                    <div v-if="forecast.sales_forecast" class="sales-forecast" ref="scrollContainer3">
                        <div class="row sales-forecast-projected">
                            <span>{{ summary_hours.forecast.morning }}€</span>
                            <span>{{ summary_hours.forecast.afternoon }}€</span>

                            <span>{{ summary_hours.forecast.total }} €</span>
                        </div>
                        <div class="row sales-forecast-real">
                            <span v-if="summary_hours.actual.morning"> {{ summary_hours.actual.morning }}€ </span>
                            <span v-else>-</span>
                            <span v-if="summary_hours.actual.afternoon"> {{ summary_hours.actual.afternoon }}€ </span>
                            <span v-else>-</span>

                            <span v-if="forecast.sales_forecast">{{ summary_hours.actual.total }} €</span>
                        </div>
                        <div class="row sales-forecast-difference">
                            <span v-if="summary_hours.diff.morning" :class="{ positive: summary_hours.diff.morning > 0, negative: summary_hours.diff.morning < 0 }"> {{ summary_hours.diff.morning }}€ </span>
                            <span v-else>-</span>
                            <span v-if="summary_hours.diff.afternoon" :class="{ positive: summary_hours.diff.afternoon > 0, negative: summary_hours.diff.afternoon < 0 }"> {{ summary_hours.diff.afternoon }}€ </span>
                            <span v-else>-</span>

                            <span v-if="summary_hours.diff.total" :class="{ positive: summary_hours.diff.total > 0, negative: summary_hours.diff.total < 0 }"> {{ summary_hours.diff.total }} € </span>
                            <span v-else>-</span>
                        </div>
                        <div class="row sales-forecast-difference-percentatge">
                            <span v-if="summary_hours.diff_delta.morning" :class="{ positive: summary_hours.diff_delta.morning > 0, negative: summary_hours.diff_delta.morning < 0 }"> {{ summary_hours.diff_delta.morning }} % </span>
                            <span v-else>-</span>
                            <span v-if="summary_hours.diff_delta.afternoon" :class="{ positive: summary_hours.diff_delta.afternoon > 0, negative: summary_hours.diff_delta.afternoon < 0 }"> {{ summary_hours.diff_delta.afternoon }} % </span>
                            <span v-else>-</span>

                            <span v-if="summary_hours.diff_delta.total" :class="{ positive: summary_hours.diff_delta.total > 0, negative: summary_hours.diff_delta.total < 0 }"> {{ summary_hours.diff_delta.total }} %</span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-content">
                <div class="products">
                    <div class="item" v-for="product in filteredProducts" :key="product.id">
                        <div class="icon" :style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                        <div class="content">
                            <div class="name">
                                {{ product.name }}
                            </div>
                            <div class="container">{{ product.containers[product.active_container] ? getContainerName(product.containers[product.active_container].name) : '' }}</div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="forecast">
                        <div class="item" v-for="product in filteredProducts" :key="product.id" ref="scrollContainer">
                            <div class="header-group">
                                <span style="cursor: pointer" @click="printProduct(product.id, 'morning')" class="value" :class="{ current: currentTimeframe == 'morning' }">{{ getTotal(product.id).morning }}</span>
                                <span style="cursor: pointer" @click="printProduct(product.id, 'afternoon')" class="value" :class="{ current: currentTimeframe == 'afternoon' }">{{ getTotal(product.id).afternoon }}</span>
                                <span style="cursor: pointer" @click="printProduct(product.id, 'total')" class="value total">{{ getTotal(product.id).total }}</span>
                                <span class="value">{{ getTotal(product.id).recalculated }}</span>
                            </div>
                            <div class="header-group">
                                <span class="value">{{ getTotal(product.id).printed }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'preparationView',
    data() {
        return {
            isExpanded: false,
            showBaths: true
        }
    },
    props: {
        products: {
            type: Array
        },
        date: { type: String }
    },
    computed: {
        forecast() {
            return this.$store.getters['production/getForecast']
        },
        currentTimeframe() {
            // If hour is before 16:00, it's morning. If not, it's afternoon. Only for today
            if (moment().isSame(this.date, 'day')) {
                return moment().hour() < 16 ? 'morning' : 'afternoon'
            }
            return ''
        },
        summary() {
            if (this.forecast.summary && this.forecast.sales_forecast) {
                var current_level = false
                var current_forecast = 0

                if (true || (moment(this.date).isSame(moment(), 'day') && this.forecast.pmix_real && this.forecast.pmix_real.levels)) {
                    var key = false
                    if (moment(this.date).isSame(moment(), 'day')) {
                        key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    } else {
                        key = moment(Math.floor(moment(this.date).add('days', 1).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    }

                    for (var idx in this.forecast.sales_forecast.general.sales_forecast) {
                        if (idx < key) {
                            current_forecast += this.forecast.sales_forecast.general.sales_forecast[idx]
                            // current_forecast += this.forecast.sales_forecast.delivery.sales_forecast[idx]
                        }
                    }

                    current_forecast = Math.round(current_forecast, 2)
                }

                var current_sales = this.forecast.sales_real ? this.forecast.sales_real.general.total : 0

                return {
                    date: moment(this.forecast.summary.ref_general).isValid() ? moment(this.forecast.summary.ref_general).format('DD/MM/YYYY') : this.forecast.summary.ref_general,
                    date_delivery: moment(this.forecast.summary.ref_delivery).isValid() ? moment(this.forecast.summary.ref_delivery).format('DD/MM/YYYY') : this.forecast.summary.ref_delivery,
                    base: this.forecast.summary.sales_base,
                    forecast: Math.round(this.forecast.summary.total),
                    current_forecast: current_forecast,
                    current_sales: current_sales,
                    diff: current_sales - current_forecast,
                    current: this.forecast.sales_real ? this.forecast.sales_real.general.total : 0,
                    current_delta: Math.round(((current_sales - current_forecast) / current_forecast) * 100)
                }
            }
            return {}
        },
        summary_hours() {
            var result = {
                forecast: { morning: 0, afternoon: 0, total: 0 },
                forecast_current: { morning: 0, afternoon: 0, total: 0 },
                actual: { morning: 0, afternoon: 0, total: 0 },
                diff: { morning: 0, afternoon: 0, total: 0 },
                diff_delta: { morning: 0, afternoon: 0, total: 0 }
            }

            var key = false
            if (moment(this.date).isSame(moment(), 'day')) {
                key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            } else {
                key = moment(Math.floor(moment(this.date).add('days', 1).add('hours', 4).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            }

            if (this.forecast.summary && this.forecast.sales_forecast) {
                const salesForecast = this.forecast.sales_forecast.general.sales_forecast
                let forecast = { morning: 0, afternoon: 0, total: 0 }
                let forecast_current = { morning: 0, afternoon: 0, total: 0 }
                let current = { morning: 0, afternoon: 0, total: 0 }
                let diff = { morning: 0, afternoon: 0, total: 0 }
                let diff_delta = { morning: 0, afternoon: 0, total: 0 }

                let hours = []
                if (this.forecast.sales_real) {
                    for (var idx in this.forecast.sales_real.general.sales) {
                        hours.push(moment(idx).format('YYYY-MM-DD HH:mm:ss'))
                    }
                }
                for (var idx in salesForecast) {
                    if (!hours.includes(idx)) hours.push(idx)
                }

                for (var i in hours) {
                    var idx = hours[i]
                    const isMorning = moment(idx).unix() < moment(this.date).add('hours', 16).unix()
                    let idx_sales = moment(idx).format('YYYY-MM-DD HH:mm')

                    let val_forecast = this.forecast.sales_forecast.general.sales_forecast[idx] ? this.forecast.sales_forecast.general.sales_forecast[idx] : 0
                    let val_real = this.forecast.sales_real ? this.forecast.sales_real.general.sales[idx_sales] : 0
                    let val_diff = val_real - val_forecast

                    if (isMorning) {
                        forecast.morning += val_forecast
                        if (idx < key) {
                            forecast_current.morning += val_forecast
                            current.morning += val_real
                            diff.morning += val_diff
                        }
                    } else {
                        forecast.afternoon += val_forecast
                        if (idx < key) {
                            current.afternoon += val_real
                            diff.afternoon += val_diff
                            forecast_current.afternoon += val_forecast
                        }
                    }
                    forecast.total += val_forecast

                    if (idx < key) {
                        current.total += val_real
                        diff.total += val_diff
                        forecast_current.total += val_forecast
                    }

                    result.forecast = forecast
                    result.actual = current
                    result.diff = diff
                    result.forecast_current = forecast_current
                }

                result.forecast.morning = Math.round(result.forecast.morning, 2)
                result.forecast.afternoon = Math.round(result.forecast.afternoon, 2)
                result.forecast.total = Math.round(result.forecast.total, 2)

                result.forecast_current.morning = Math.round(result.forecast_current.morning, 2)
                result.forecast_current.afternoon = Math.round(result.forecast_current.afternoon, 2)
                result.forecast_current.total = Math.round(result.forecast_current.total, 2)

                result.actual.morning = Math.round(result.actual.morning, 2)
                result.actual.afternoon = Math.round(result.actual.afternoon, 2)
                result.actual.total = Math.round(result.actual.total, 2)

                result.diff.morning = Math.round(result.diff.morning, 2)
                result.diff.afternoon = Math.round(result.diff.afternoon, 2)
                result.diff.total = Math.round(result.diff.total, 2)

                if (result.diff.total != 0) {
                    result.diff_delta.morning = Math.round((result.diff.morning / result.forecast_current.morning) * 100, 2)
                    result.diff_delta.afternoon = Math.round((result.diff.afternoon / result.forecast_current.afternoon) * 100, 2)
                    result.diff_delta.total = Math.round((result.diff.total / result.forecast_current.total) * 100, 2)
                }
            }
            return result
        },
        filteredProducts() {
            return this.products.filter((product) => [1, 2, 3].includes(product.type))
        }
    },
    methods: {
        roundConditional(value, units) {
            const factor = value / units < 1 ? 0.1 : 0.25
            let roundedValue = Math.round(Math.ceil(value / units / factor) * factor * 100) / 100
            return this.showBaths ? Math.ceil(roundedValue) : roundedValue
        },
        getTotal(productId) {
            var product = this.filteredProducts.find((item) => item.id === productId)
            var units = product.containers[product.active_container].units

            if (this.forecast && this.forecast.products && typeof this.forecast.products.preparation[productId] !== 'undefined') {
                // const total = this.roundConditional(this.forecast.products.preparation[productId].total, units)
                var morning = this.forecast.products.preparation[productId].labels.morning
                var afternoon = this.forecast.products.preparation[productId].labels.afternoon
                var timeframe = this.forecast.products.preparation[productId].labels.total

                const total = this.showBaths ? timeframe.reduce((acc, curr) => acc + this.roundConditional(curr, units), 0) : this.roundConditional(this.forecast.products.preparation[productId].total, units)
                const recalculatedExists = typeof this.forecast.products.preparation[productId].recalculated !== 'undefined'

                let morning_value = this.showBaths ? morning.reduce((acc, curr) => acc + this.roundConditional(curr, units), 0) : this.roundConditional(this.forecast.products.preparation[productId].morning, units)
                let afternoon_value = this.showBaths ? afternoon.reduce((acc, curr) => acc + this.roundConditional(curr, units), 0) : this.roundConditional(this.forecast.products.preparation[productId].afternoon, units)
                let total_value = total
                let recalculated_value = recalculatedExists ? this.roundConditional(this.forecast.products.preparation[productId].recalculated, units) : total
                let printed_value = this.roundConditional(this.forecast.products.preparation[productId].printed || 0, units)

                return {
                    morning: morning_value > 0 ? morning_value : '-',
                    afternoon: afternoon_value > 0 ? afternoon_value : '-',
                    total: total_value > 0 ? total_value : '-',
                    recalculated: recalculated_value > 0 ? recalculated_value : '-',
                    printed: printed_value > 0 ? printed_value : '-'
                }
            }

            return {
                total: '-',
                morning: '-',
                afternoon: '-',
                recalculated: '-',
                printed: '-'
            }
        },
        toggleSalesSection() {
            this.isExpanded = !this.isExpanded

            var self = this
            this.$nextTick(() => {
                if (self.$refs.scrollContainer4) {
                    self.$refs.scrollContainer4.scrollTo({ top: 0, behavior: 'smooth' })
                }
            })
        },
        sendReportMail() {
            const self = this
            const employee = this.$store.getters['loginUser/getUserInformation']
            this.$popup.message({
                message: self.$t('popup.pls.message', { date: moment(this.date).format('DD [de] MMMM [del] YYYY') }),
                textSave: self.$t('popup.pls.confirm'),
                callSave: function () {
                    self.$store.dispatch('production/exportForecast', { date: self.date })
                }
            })
        },
        refresh() {
            this.isExpanded = false
            this.loadForecast()
        },
        loadForecast() {
            let self = this
            this.$overlay.loading()
            this.$store.dispatch('production/loadForecast', this.date).then(() => {
                self.$overlay.hide()
            })
        },
        printTimeFrame(timeframe) {
            var self = this
            let references = []
            this.filteredProducts.forEach((product) => {
                let value = self.getTotal(product.id)[timeframe]
                value = value == '-' || value == '' ? 0 : value
                if (value > 0) {
                    var product_labels = self.calculateProductLabels(product.id, timeframe)
                    references.push(...product_labels)
                }
            })

            // remove all references that quantity is 0
            references = references.filter((item) => item.quantity > 0)
            log('references', references)
            switch (timeframe) {
                case 'morning':
                    timeframe = 'Mañana'
                    break
                case 'afternoon':
                    timeframe = 'Tarde'
                    break
                case 'total':
                    timeframe = 'Todo el día'
                    break
                case 'recalculated':
                    timeframe = 'Total ajustado'
                    break
            }
            this.$popup.confirm({
                message: 'Vas a imprimir las etiquetas de <b>' + timeframe + '</b> (podrás modificar la cantidad de cada producto) ¿Deseas imprimir las etiquetas?',
                textSave: 'Imprimir',
                textCancel: 'Cancelar',
                callSave: function () {
                    self.$store.dispatch('label/prepareTemporalCollection', references).then((response) => {
                        log(response)
                        self.$router.push({ name: 'Label', params: { print: true, type: 'collection', id: response.id } })
                    })
                }
            })
        },
        calculateProductLabels(id, timeframe) {
            var product = this.filteredProducts.find((item) => item.id === id)
            if (product.length > 0) {
                product = product[0]
            }
            log('product', product)

            var units = product.containers[product.active_container].units
            var suffix = product.containers[product.active_container].name.includes('gr)') ? 'gr' : 'ud'
            var max_units = 1
            var calc = units
            var format = suffix
            var transform = false
            if (product.containers[product.active_container].label) {
                calc = product.containers[product.active_container].label.calc
                format = product.containers[product.active_container].label.units
                max_units = parseInt(product.containers[product.active_container].name.match(/\((\d+)/)[1]) || 1
                transform = true
            }
            const factor = 0.1

            let references = []

            // if timeframe is total then we need the sum of morning and afternoon
            var timeframe_value = []
            if (timeframe === 'total' || timeframe === 'recalculated') {
                // concatenar array de morning y afternoon
                var morning = this.forecast.products.preparation[id].labels.morning
                var afternoon = this.forecast.products.preparation[id].labels.afternoon
                timeframe_value = this.forecast.products.preparation[id].labels.total
            } else {
                timeframe_value = this.forecast.products.preparation[id].labels[timeframe]
            }
            log('timeframe_value', timeframe_value)
            for (var idx in timeframe_value) {
                var value = timeframe_value[idx]

                if (value > 0) {
                    references.push({
                        id: product.id,
                        state_id: product.label_states.preparation,
                        quantity: Math.floor(value / max_units / calc),
                        package: transform ? max_units + format : units + format
                    })
                    var remaining = value % units

                    function roundToNearest(value, x) {
                        let step = x / 10
                        let values = Array.from({ length: 10 }, (_, i) => step * (i + 1))
                        return values.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev))
                    }

                    remaining = roundToNearest(remaining, units)

                    if (remaining > 0) {
                        references.push({
                            id: product.id,
                            state_id: product.label_states.preparation,
                            quantity: 1,
                            package: transform ? (Math.round((remaining * max_units) / units) == 0 ? 1 : Math.round((remaining * max_units) / units)) + format : Math.round(remaining) + format
                        })
                    }
                }
            }

            log('references', references)

            // group references by package summing quantity
            const groupedReferences = references.reduce((acc, curr) => {
                const key = curr.package
                if (!acc[key]) {
                    acc[key] = { ...curr, quantity: 0 }
                }
                acc[key].quantity += curr.quantity
                return acc
            }, {})

            // remove references with quantity 0 to groupedReferences
            Object.keys(groupedReferences).forEach((key) => {
                if (groupedReferences[key].quantity === 0) {
                    delete groupedReferences[key]
                }
            })

            //const total_labels = references.reduce((acc, curr) => acc + curr.quantity, 0)

            // console.warn(groupedReferÇences, product)

            return Object.values(groupedReferences)
        },
        printProduct(id, timeframe) {
            log('printProduct', id, timeframe)
            var self = this
            var product = this.filteredProducts.find((item) => item.id === id)

            var references = this.calculateProductLabels(id, timeframe)
            log('references', references)
            const total_labels = references.reduce((acc, curr) => acc + curr.quantity, 0)

            if (total_labels > 0) {
                this.$popup.confirm({
                    message: 'Vas a imprimir ' + total_labels + ' etiquetas de <b>' + product.name + '</b> (podrás modificar la cantidad) ¿Deseas imprimir las etiquetas?',
                    textSave: 'Imprimir',
                    textCancel: 'Cancelar',
                    callSave: function () {
                        self.$store.dispatch('label/prepareTemporalCollection', references).then((response) => {
                            log(response)
                            self.$router.push({ name: 'Label', params: { print: true, type: 'collection', id: response.id } })
                        })
                    }
                })
            }
        },
        getContainerName(name) {
            return name.split('(')[0].trim()
        }
    }
}
</script>
<style lang="scss" scoped>
.table {
    display: flex;
    flex-direction: column;
    height: 100%;

    .positive {
        font-family: $text-bold !important;
        color: $color-success-500;
    }
    .negative {
        font-family: $text-bold !important;
        color: $color-error-500;
    }

    .topbar {
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        overflow: hidden;
        align-items: flex-start;
        .content {
            display: flex;
            width: 65%;
            justify-content: flex-end;
        }

        button {
            height: 50px;
            width: 50px;
            font-size: 30px;
            background-color: #fff;
        }
        .sales-information {
            width: 35%;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            flex-shrink: 0;
            height: 50px;
            gap: 8px;

            .nav {
                border-radius: 4px;
                display: flex;
                align-self: flex-start;
                width: 50px;
                height: 50px;
                align-items: center;
                &.sales-button {
                    @include background($image: img('chart_inactive.svg'), $size: 20px, $color: #fff);
                }
                &.active {
                    &.sales-button {
                        @include background($image: img('chart_active.svg'), $size: 20px, $color: $main);
                    }
                }
                &.mail {
                    @include background($image: img('mail.svg'), $size: 25px, $color: #fff);
                }
                &.refresh {
                    @include background($image: img('reload.svg'), $size: 20px, $color: #fff);
                }
            }

            .switch-container {
                display: flex;
                align-items: center;
                gap: 8px;

                .switch-label {
                    color: $color-neutral-600;
                    @include font-size(s);
                }

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 50px;
                    height: 24px;

                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;

                        &:checked + .slider {
                            background-color: $main;

                            &:before {
                                transform: translateX(26px);
                            }
                        }
                    }

                    .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $color-neutral-400;
                        transition: 0.4s;
                        border-radius: 34px;

                        &:before {
                            position: absolute;
                            content: '';
                            height: 16px;
                            width: 16px;
                            left: 4px;
                            bottom: 4px;
                            background-color: white;
                            transition: 0.4s;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        .navBar {
            width: calc(100%);
            display: flex;
            justify-content: space-between;
            gap: 25px;
            height: 50px;

            .header-group {
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 5px;

                &:first-child {
                    width: 80%;
                }
                &:last-child {
                    width: 20%;
                }

                .header {
                    color: $color-neutral-500;
                    font-family: $text-bold;
                    text-transform: uppercase;
                    @include font-size(s);
                    border-bottom: 2px solid $color-neutral-300;
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                    text-align: center;
                    height: auto;
                }

                .columns-group {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 10px;
                    align-items: start;
                }

                .nav-elements {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    .printer {
                        width: 50px;
                        height: 50px;
                        @include background($image: img('printer.svg'), $size: 20px, $color: #fff);
                        display: none;
                    }

                    .timeframe {
                        @include display-flex();
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        border-radius: 4px;

                        &.current {
                            background: rgba($color-warning-500, 1);

                            .title {
                                color: #fff;
                            }
                            .description {
                                color: #fff;
                            }
                        }

                        .title {
                            font-family: $text-bold;
                            @include font-size(s);
                            text-transform: uppercase;
                        }

                        .description {
                            color: $color-neutral-600;
                            @include font-size(xs);
                            margin-top: -5px;
                        }
                    }
                }
            }
        }
        .summary-title {
            text-align: center;
            width: 125px;
            flex-shrink: 0;

            span {
                word-wrap: break-word;
                @include font-size(m);
                color: $color-neutral-600;
                font-family: $text-bold;
                text-transform: uppercase;
            }
        }
    }
    .content-sections {
        height: calc(100% - 75px);
        overflow: auto;
        .sales-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            .sales-text {
                width: 35%;
                padding-left: 40px;
                span {
                    display: block;
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
            .sales-content {
                width: 65%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                box-sizing: border-box;
                overflow: hidden;
                padding-right: 25px;

                .sales-forecast {
                    width: 80%;
                    display: grid;
                    flex-direction: column;
                    grid-template-rows: repeat(4, 1fr);
                    align-items: center;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    scroll-behavior: smooth;

                    .row {
                        display: grid;
                        flex-direction: row;
                        gap: 10px;
                        width: 100%;
                        grid-template-columns: repeat(4, 1fr);
                        text-align: center;

                        span {
                            text-align: center;
                        }

                        .scroll-item {
                            flex: 0 0 80px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100px;
                            max-width: 100px;
                            min-width: 100px;
                            margin-left: 5px;

                            span {
                                text-align: center;
                                width: 100px;
                                font-family: $text;

                                &.positive {
                                    font-family: $text-bold;
                                    color: $color-success-500;
                                }
                                &.negative {
                                    font-family: $text-bold;
                                    color: $color-error-500;
                                }
                            }
                        }
                    }
                }
            }
            .sales-summary {
                width: 125px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                span {
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
        }
        .product-content {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 16px;

            .products {
                width: 40%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .item {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.6);
                    }
                    &.frost {
                        &:hover {
                            background-color: $main-t90;
                        }
                    }

                    .icon {
                        width: 35px;
                        height: 35px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        display: inline-block;
                    }
                    .content {
                        flex-direction: column;
                        width: 100%;
                        padding-left: 8px;
                        .name {
                            display: flex;
                            align-items: center;
                            font-family: $text-bold;
                            text-transform: uppercase;
                            @include font-size(sm);
                        }
                        .icon {
                            /* background-image: img('/img/defrost.svg'); */
                            height: 25px;
                            width: 25px;
                        }
                        .container {
                            display: block;
                            font-family: $text;
                            color: $color-neutral-600;
                            display: block;
                        }
                    }
                }
            }

            .content {
                display: flex;
                width: 60%;
                justify-content: flex-end;
                width: 100%;

                .forecast {
                    width: calc(100% - 50px);
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: stretch;
                    flex-direction: column;
                    gap: 10px;

                    .item {
                        height: 50px;
                        display: flex;
                        width: 100%;
                        gap: 25px;

                        .header-group {
                            text-align: center;
                            display: flex;
                            flex-direction: row;
                            gap: 5px;
                            display: grid;
                            flex-direction: row;
                            align-items: center;
                            overflow-x: hidden;
                            scroll-behavior: smooth;
                            padding: 0;
                            gap: 5px;

                            &:first-child {
                                width: 80%;
                                grid-template-columns: repeat(4, 1fr);
                            }
                            &:last-child {
                                width: 20%;
                                grid-template-columns: repeat(1, 1fr);
                            }
                        }

                        .value {
                            font-family: $text;
                            @include font-size(m);
                            display: block;
                            height: 45px;
                            background: #fff;
                            border: 2px solid #ddd;
                            border-radius: 3px;
                            @include display-flex();
                            @include align-items();
                            @include justify-content();
                            position: relative;
                            //width: 250px;

                            &.pending {
                                border-color: $color-error-500;
                                font-family: $text-bold;
                                color: $color-error-500;
                            }
                            &.started {
                                border-color: $color-warning-500;
                                font-family: $text-bold;
                                color: $color-warning-500;
                            }
                            &.completed {
                                border-color: $color-success-500;
                            }
                            &.null {
                                border-color: #ddd;
                                font-family: $text;
                            }
                            &.total {
                                font-family: $text-bold;
                            }

                            .increase {
                                display: block;
                                position: absolute;
                                bottom: 0px;
                                @include font-size(xs);
                                display: flex;
                                align-items: center;

                                &:before {
                                    display: inline-block;
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-radius: 3px;
                                    margin-right: 2px;
                                }

                                &.positive {
                                    &:before {
                                        border-bottom: 6px solid green;
                                    }
                                }
                                &.negative {
                                    &:before {
                                        border-top: 6px solid #f00;
                                    }
                                }
                            }

                            &.current {
                                // background-color: $color-warning-500;
                                // border-color: $color-warning-500;
                                // font-family: $text-bold;
                                // color: #fff;

                                .value {
                                    // color: #fff;
                                }
                                .increase {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            .summary {
                width: 125px;
                text-align: center;
                .item {
                    width: 100%;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .value {
                        font-family: $text-bold;
                        @include font-size(l);
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }
        }
    }
}

//RBI DEVICE
@media (max-width: 1285px) {
    .table {
        .topbar {
            .navBar {
                .nav-elements {
                    .timeframe {
                        .title {
                            @include font-size(s);
                        }
                        .description {
                            @include font-size(xs);
                        }
                    }
                    .printer {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}
</style>
